<template>
    <div class="login-container">
        loading...
    </div>
</template>

<script>
  import { setToken } from "@/utils/token.js";
  export default {
    name: "LoginDone",
    components: { },
    data() {
      return {
        current: 0, // 当前选中的 tab-index
        tab: [ "账号登录", "扫码登录"],
      };
    },
    // vue 在模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作。
    mounted() {
      // 接收url参数
      var query = this.$route.query;
      let token = query.token;
      setToken(token, "登录");
      // this.$message.success();
      this.$router.push({
        path: "/userArgument",
      });
      this.$nextTick(() => {
          this.getCurrUserItem();
      });
    },
  };
</script>